/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field, Formik, FieldArray, useFormikContext } from 'formik';
import { cloneDeep } from 'lodash';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import useFetch from 'use-http';
import * as Yup from 'yup';
import WorkplaceService from '../../../__mock__/WorkplaceService';
import {
  useCartDispatch,
  useCartState,
} from '../../context/CartContext/CartContext';
import { useSettingsState } from '../../context/SettingsContext';
import { useUserState, useUserDispatch } from '../../context/UserContext';
import personalCodeValidation from '../../hooks/personalCodeValidation';
import usePhoneValidation from '../../hooks/usePhoneValidator';
import Button from '../../shared/Button';
import Checkbox from '../../shared/Checkbox';
import Container from '../../shared/Container';
import ErrorModal from '../../shared/ErrorModal';
import Form from '../../shared/Form';
import Loader from '../../shared/Loader';
import Modal from '../../shared/Modal';
import NoResults from '../../shared/NoResults';
import Page from '../../shared/Page';
import Tabs from '../../shared/Tabs';
import TextField from '../../shared/TextField';
import Summary from './Summary';
import Styled from './CartPageStyles/CartPage.styles';
import validateEmail from '../../hooks/validateEmail';
import ChildDropdown from '../../shared/ChildDropdown/ChildDropdown';
import { exponeaCapture } from '../../hooks/exponeaCapture';
import uniqid from 'uniqid';
import SelectField from '../../shared/SelectField';

const USE_MOCKS = false;

const BasketPage = () => {
  const { t } = useTranslation();
  const { agreements } = useSettingsState();
  const history = useHistory();
  const { step: step, registrationServices: products } = useCartState();
  const dispatch = useCartDispatch();
  const userState = useUserState();
  const [cartItems, setCartItems] = useState([]);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const [showPsdfTabName, setShowPsdfTabName] = useState(false);
  const { get, request } = useFetch('/Services/WorkplaceServices');
  const [backPath, setBackPath] = useState();
  const [underAge, setUnderageModalVisibile] = useState(false);
  const [selectedChildEspbiEsi, setSelectedChildEspbiEsi] = useState();
  const validatePhoneNumber = usePhoneValidation();
  const userDispatch = useUserDispatch();
  const [validPhoneNumber, setValidPhoneNumber] = useState();
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState();
  const [showPhoneValidationModal, setShowPhoneValidationModal] =
    useState(false);
  const [showChildAgeRestrictionModal, setShowChildAgeRestrictionModal] =
    useState(false);
  const [showPrivateSocialPaymentModal, setShowPrivateSocialPaymentModal] =
    useState(false);
  const [privateSocialPayment, setPrivateSocialPayment] = useState(false);
  const [showGiftCoupon, setShowGiftCoupon] = useState(false);
  const [showDiscountCoupon, setShowDiscountCoupon] = useState(false);
  const [discountCodeNumber, setDiscountCodeNumber] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalInitialSum, setTotalInitialSum] = useState(0);
  const cartState = useCartState();
  const [initialTotalPrice, setInitialTotalPrice] = useState();
  const [totalCouponsApplied, setTotalCouponsApplied] = useState(0);
  const [changeCount, setChangeCount] = useState(0);
  const [appliedCouponNumbers, setAppliedCouponNumbers] = useState([]);
  const [errorModalMessage, setErrorModalMessage] = useState();
  const [serviceId, setServiceId] = useState();
  const [kids, setKids] = useState();
  const [childSelected, setChildSelected] = useState(false);
  const [selectedChild, setSelectedChild] = useState('');
  const [priceFromSummary, setPriceFromSummary] = useState(0);
  const { request: registrationRequest, response: registrationResponse } =
    useFetch('/Registrations');
  const { get: saveValidationLog, response: saveValidationLogResponse } =
    useFetch('/Logs/saveValidationLog');
  const { post: checkCoupon, response: checkCouponResponse } = useFetch(
    '/CouponManagement/CheckCoupon',
  );
  const { post: checkDiscountCode, response: checkDiscountCodeResponse } =
    useFetch('/DiscountManagement/GetDiscount');

  const { get: getServiceTypeId, response: getServiceTypeIdResponse } =
    useFetch('/Services/ServiceTypeId');

  const { request: serviceRequest, response: serviceGetResponse } =
    useFetch('/Services');

  const { post: updatePersonalInsurance, response: updatePersonalInsuranceResponse } = useFetch(
    '/UserAccount/PersonalInsurance',
  );

  const [
    validatePersonalCode,
    maxAgeFromPersonalCode,
    minAgeFromPersonalCode,
    birthDateFromPersonalCode,
  ] = personalCodeValidation();

  const { request: timeRequest } = useFetch(`/TimeSlots`);

  const formRef = useRef();
  const formRefCoupons = useRef();
  const formRefDiscount = useRef();
  const checkBoxRef = useRef();
  const requiredMessage = t('validation.requiredField');
  const vgskServiceTypeId = process.env.REACT_APP_VGSK_SERVICE_TYPE_ID;
  const laboratoryServiceTypeExternalId = process.env.REACT_APP_LABEXTERNALID;
  const gastroenterologyServiceTypeExternalId =
    process.env.REACT_APP_GASTROENTEROLOGY_SERVICE_TYPE_EXTERNAL_ID;

  const insuranceCompanies = [
    { value: 'BTA', label: 'BTA' },
    { value: 'Compensa', label: 'Compensa' },
    { value: 'Ergo', label: 'Ergo' },
    { value: 'IF draudimas', label: 'IF draudimas' },
    { value: 'Gjensidige', label: 'Gjensidige' },
    { value: 'Lietuvos draudimas', label: 'Lietuvos draudimas' },
    { value: 'Seesam', label: 'Seesam' }
  ];

  useEffect(() => {
    if (userState?.contacts) {
      const kids = [];
      userState?.contacts.forEach((k) => {
        if (k.personalCode) {
          var testRes = maxAgeFromPersonalCode(k.personalCode, 18);
          if (!testRes) {
            kids.push(k);
          }
        } else {
          kids.push(k);
        }
      });
      setKids(kids);
    }
  }, [userState?.contacts]);

  useEffect(() => {
    setBackPath(history.location.state?.backPath);
    if (USE_MOCKS) {
      setCartItems(
        [WorkplaceService].map((d, i) => {
          d.orderData = products[i];
          return d;
        }),
      );
    } else {
      if (products.length) {
        let multiProducts = addLaboratoryMultipleServicesToProducts(products);
        Promise.all(
          multiProducts.map(({ workplaceId, serviceId }) => {
            setServiceId(serviceId);
            return get(`/${workplaceId}/${serviceId}`);
          }),
        ).then(async (data) => {
          const updatedDataPromises = data.map(async (d, i) => {
            d.orderData = multiProducts[i];
            d.additionalServices = d.additionalServices.concat(
              await setAdditionalServicesByResearchArea(d, multiProducts[i])
            );
            d.isPaidLaboratoryService =
              multiProducts[i].isPaidLaboratoryService;
            return d;
          });

          const updatedData = await Promise.all(updatedDataPromises);
          setCartItems(updatedData);
        });
      }
    }
  }, [backPath, get, history.location.state?.backPath, products]);

  const setAdditionalServicesByResearchArea = async (
    workplaceService,
    orderData,
  ) => {
    const selectedTimeSlot = orderData?.timeSlot;
    const service = workplaceService;
    if (
      shouldUseAdditionalServicesByResearchArea(workplaceService) &&
      !!selectedTimeSlot &&
      !!selectedTimeSlot?.researchAreaId &&
      selectedTimeSlot.isPaid
    ) {
      const params = new URLSearchParams({
        serviceId: service.serviceId,
        researchAreaId: selectedTimeSlot.researchAreaId,
        timeSlotDateTime: selectedTimeSlot.startDate,
        departmentExternalId: service.departmentExternalId,
      });
      const url = `ResearchAreaDayOfWeekAdditionalServices?${params.toString()}`;
      const response = await serviceRequest.get(url);
      return [...response, ...service.mandatoryOtherServices ?? []];
    }

    return service.additionalService ?? [];
  };

  const shouldUseAdditionalServicesByResearchArea = async (
    workplaceService,
  ) => {
    if (!workplaceService || !workplaceService.serviceTypeExternalId) {
      return false;
    }
    return (
      gastroenterologyServiceTypeExternalId ==
      workplaceService.serviceTypeExternalId
    );
  };

  useEffect(() => {
    return () => {
      if (history.location.pathname === '/selfservice') {
        dispatch({
          type: 'SET_BACK_URL',
          url: `/checkout`,
        });
      } else if (
        backPath !== undefined &&
        history.location.pathname !== backPath
      ) {
        dispatch({
          type: 'RESET_CART',
        });
      } else if (history.action === 'POP') {
        dispatch({
          type: 'RESET_CART',
        });
      }
    };
  }, [backPath, dispatch, history]);

  useEffect(() => {
    if (showGiftCoupon) {
      handleTotalCoupons(null);
    }
  }, [changeCount, showGiftCoupon]);

  useEffect(() => {
    if (totalPrice > 0) {
      setInitialTotalPrice(
        (prev) => prev < 1 && totalPrice + totalCouponsApplied,
      );
    }
  }, [totalPrice]);

  useEffect(() => {
    if (products) {
      let singleProducts = [];
      if (products.length) {
        singleProducts = [products[products.length - 1]];
      }
      singleProducts.forEach((prod) => {
        if (!prod.timeSlot.isPaid) {
          setShowPsdfTabName(true);
        }
      });
    }
  }, [products]);

  useEffect(() => {
    if (showGiftCoupon) {
      formRefCoupons.current.values.coupons = [
        { couponNumber: '', couponLabel: false, inputVisible: true },
      ];
    } else {
      setTotalCouponsApplied(0);
    }
  }, [showGiftCoupon]);

  useEffect(() => {
    if (formRefCoupons?.current?.values.coupons.length === 0) {
      setShowGiftCoupon(false);
    }
  }, [formRefCoupons?.current?.values.coupons]);

  function addLaboratoryMultipleServicesToProducts(products) {
    if (
      products[0].laboratoryMultipleServices &&
      products[0].laboratoryMultipleServices.length > 0
    ) {
      const newProducts = products[0].laboratoryMultipleServices.map(
        (service) => {
          const newProduct = {
            ...products[0],
            id: uniqid(),
            serviceId: service.serviceId,
            isPaidLaboratoryService: service.isPaidLaboratoryService,
          };
          delete newProduct.laboratoryMultipleServices;
          return newProduct;
        },
      );
      return [...products, ...newProducts];
    }
    return products;
  }

  const areLaboratoryServices = () => {
    return (
      cartItems[0].serviceTypeExternalId.toString() ===
      laboratoryServiceTypeExternalId.toString()
    );
  };

  const areAnyAdditionalPaidServices = (isPaid) => {
    return cartItems.find(
      (cartItem) =>
        cartItem.automationRuleCodes.includes(isPaid ? 2 : 1) &&
        cartItem.additionalServices?.length > 0 &&
        cartItem.additionalServices?.find((service) => service.price > 0),
    );
  };

  const areNoAdditionalPaidServices = (isPaid) => {
    return cartItems.every(
      (cartItem) =>
        !cartItem.automationRuleCodes.includes(isPaid ? 2 : 1) ||
        cartItem.additionalServices?.length === 0 ||
        cartItem.additionalServices?.every((service) => service.price === 0),
    );
  };

  function handleFormSubmit(formData, { setErrors, setSubmitting }) {
    showPaymentOptions(formData, { setErrors, setSubmitting })

    if (
      userState?.isLoggedIn
      && userState?.id
      && formData?.insuranceCompany
      && formData?.insuranceNumber
      && (formData?.insuranceNumber != userState?.insuranceCardNumber
        || formData?.insuranceCompany != userState?.insuranceCompanyName)
    ) {
      updatePersonalInsurance({
        UserId: userState.id,
        InsuranceCompanyName: formData.insuranceCompany,
        InsuranceCardNumber: formData.insuranceNumber
      })
    }
  }

  function showPaymentOptions(formData, { setErrors, setSubmitting }) {
    setSubmitting(true);
    setFormSubmitting(true);
    let paymentType = 'Paysera';
    let singleProducts = [];

    if (products) {
      singleProducts = cartItems.map((item) => item.orderData);
    }

    singleProducts.forEach((prod, index) => {
      prod.startDate = prod.timeSlot.startDate;
      prod.childName = formData.childName;
      prod.childSurname = formData.childSurname;
      prod.childIdNumber = formData.childIdNumber;

      if (!prod.timeSlot.isPaid && !prod.timeSlot.isExtra) {
        paymentType = 'PSDF';
      }

      if (areLaboratoryServices() && index === 0) {
        prod.isPaidLaboratoryService = prod.timeSlot.isPaid;
      }

      if (prod.childIdNumber) {
        prod.childBirthDate = birthDateFromPersonalCode(formData.childIdNumber);
      }

      if (userState?.contacts) {
        if (prod.childIdNumber) {
          prod.haveKid = true;
          if (
            userState?.contacts.some(
              (x) => x.personalCode === prod.childIdNumber,
            )
          ) {
            prod.correctChild = true;
            prod.espbiEsi = userState?.contacts.filter(
              (x) => x.personalCode === prod.childIdNumber,
            )[0].espbiEsi;
          }
        } else if (childSelected) {
          prod.haveKid = true;
          prod.correctChild = true;
          prod.espbiEsi = selectedChildEspbiEsi;
        }
      }
    });

    if (cartItems.slice(1).some((item) => item.isPaidLaboratoryService)) {
      paymentType = 'Paysera';
    }

    if (privateSocialPayment) {
      paymentType = 'Social';
    }

    if (paymentType !== 'PSDF' && totalCouponsApplied >= totalPrice) {
      paymentType = 'Coupons';
    }

    if (
      paymentType === 'PSDF' &&
      cartItems[0]?.additionalServices?.length !== 0 &&
      totalCouponsApplied >= totalPrice
    ) {
      paymentType = 'PSDFWithCoupons';
    }

    if (
      initialTotalPrice > 0 &&
      totalCouponsApplied < initialTotalPrice &&
      paymentType !== 'Social' &&
      paymentType !== 'PSDF' &&
      paymentType !== 'PSDFWithCoupons'
    ) {
      paymentType = 'Paysera';
    }

    const form = cloneDeep({
      ...formData,
      couponSum: totalCouponsApplied,
      startDate: cartItems[0]?.orderData?.timeSlot?.startDate,
      registrationServices: singleProducts,
      serviceUrl: cartState.orderUrl,
      paymentType: paymentType,
      expressCareType: cartItems[0]?.orderData?.expressCareType,
      couponNumbers:
        paymentType === 'Coupons' ||
          paymentType === 'Paysera' ||
          paymentType === 'PSDFWithCoupons' ||
          paymentType === 'PSDF'
          ? appliedCouponNumbers
          : [],
      discountCode: discountCodeNumber,
      TimeSlot: {
        timeSlotId: cartItems[0]?.orderData?.timeSlot?.timeSlotId,
        startDate: cartItems[0]?.orderData?.timeSlot?.startDate,
        endDate: cartItems[0]?.orderData?.timeSlot?.endDate,
        tags: cartItems[0]?.orderData?.timeSlot?.tags,
        researchAreaId: cartItems[0]?.orderData?.timeSlot?.researchAreaId,
      },
      frontUrls: !areLaboratoryServices()
        ? {
          cancelFrontURL:
            (paymentType === 'PSDF' || paymentType === 'Social') &&
              cartItems[0]?.additionalServices?.length === 0
              ? 'payment/psdfError'
              : 'payment/error',
          acceptFrontUrl:
            (paymentType === 'PSDF' || paymentType === 'Social') &&
              cartItems[0]?.additionalServices?.length === 0
              ? 'payment/psdfAccept'
              : paymentType === 'PSDF' &&
                cartItems[0]?.additionalServices?.length > 0
                ? 'payment/psdfWithAdditionalPaymentAccept'
                : 'payment/accept',
          errorFrontUrl:
            (paymentType === 'PSDF' || paymentType === 'Social') &&
              cartItems[0]?.additionalServices?.length === 0
              ? 'payment/psdfError'
              : 'payment/error',
          test: 1,
        }
        : {
          cancelFrontURL:
            (paymentType === 'PSDF' || paymentType === 'Social') &&
              areNoAdditionalPaidServices(false)
              ? 'payment/psdfError'
              : 'payment/error',
          acceptFrontUrl:
            (paymentType === 'PSDF' || paymentType === 'Social') &&
              areNoAdditionalPaidServices(false)
              ? 'payment/psdfAccept'
              : paymentType === 'PSDF' && areAnyAdditionalPaidServices(false)
                ? 'payment/psdfWithAdditionalPaymentAccept'
                : 'payment/accept',
          errorFrontUrl:
            (paymentType === 'PSDF' || paymentType === 'Social') &&
              areNoAdditionalPaidServices(false)
              ? 'payment/psdfError'
              : 'payment/error',
          test: 2,
        },
    });

    delete form.agreement1;
    delete form.agreement2;
    delete form.agreement3;

    form.registrationServices.forEach((service) => {
      delete service.files;
      delete service.termsAndConditions;
      delete service.timeSlot;
      delete service.id;
    });
    form.patientIdNumber = form.patientIdNumber?.replace(/\s/g, '');

    if (form.patientIdNumber) {
      form.patientBirthDate = birthDateFromPersonalCode(form.patientIdNumber);
    }

    const isService = !cartItems[0].specialistId;
    let baseObj = [
      { name: 'category_1', value: cartItems[0].serviceTypeTitle },
      {
        name: 'category_2',
        value: isService
          ? cartItems[0].serviceTitle
          : cartItems[0].specialistName + ' - ' + cartItems[0].serviceTitle,
      },
      { name: 'price', value: priceFromSummary },
      { name: 'registration type', value: paymentType },
      { name: 'firstName', value: form.patientName },
      { name: 'lastName', value: form.patientSurname },
      { name: 'email', value: form.patientEmail },
      { name: 'product_id', value: cartItems[0].serviceId },
      { name: 'category_id', value: cartItems[0].categoryId },
      { name: 'mobile_phone', value: formData.patientPhoneNumber },
    ];

    if (form.childIdNumber) {
      baseObj.push({ name: 'childName', value: form.childName });
      baseObj.push({ name: 'childSurname', value: form.childSurname });
    }
    exponeaCapture(baseObj, 'booking_item');

    registrationRequest.post('', form).then(async () => {
      const response = await registrationResponse.json();

      baseObj.push({
        name: 'booking_appointment_id',
        value: cartItems[0]?.orderData?.timeSlot?.timeSlotId,
      });

      baseObj.push({
        name: 'booking_booking_id',
        value: response.orderId,
      });

      baseObj.push({
        name: 'booking_payment_gateway_id',
        value: response.orderId,
      });

      baseObj.push({
        name: 'purchase_total_cost',
        value: priceFromSummary,
      });

      sessionStorage.setItem('exponeaTrack', JSON.stringify(baseObj));

      if (registrationResponse.status >= 500) {
        setSubmitting(false);
        setFormSubmitting(false);
        setErrorModalVisible(true);

        let couponDescriptionString = response.detail.substring(
          31,
          response.detail.length,
        );
        setErrorModalMessage(couponDescriptionString);
      } else if (registrationResponse.status >= 400) {
        const { errors } = response;

        setErrors(errors);
      } else {
        userDispatch({
          type: 'SET_LEFT_CART_PAGE',
          left: true,
        });
        const payseraUrl = response.payseraUrl;

        if (
          response.payseraUrl === '' &&
          paymentType !== 'PSDF' &&
          paymentType !== 'Social'
        ) {
          dispatch({
            type: 'RESET_CART',
          });

          history.push('/payment/couponAccept');
        } else if (
          !response.payseraUrl &&
          (paymentType === 'PSDF' || paymentType === 'Social') &&
          (paymentType !== 'Social' ||
            (!areLaboratoryServices() &&
              (cartItems[0]?.additionalServices?.length === 0 ||
                (await shouldUseAdditionalServicesByResearchArea(
                  cartItems[0],
                )))) ||
            paymentType !== 'Social' ||
            (areLaboratoryServices() && areNoAdditionalPaidServices(false)))
        ) {
          history.push('/payment/psdfAccept');
        }

        if (
          (paymentType !== 'PSDF' &&
            paymentType !== 'Social' &&
            response.payseraUrl !== '') ||
          (!areLaboratoryServices() &&
            cartItems[0]?.additionalServices?.length !== 0 &&
            !(await shouldUseAdditionalServicesByResearchArea(cartItems[0]))) ||
          (areLaboratoryServices() && areAnyAdditionalPaidServices(false))
        ) {
          dispatch({
            type: 'RESET_CART',
          });

          dispatch({
            type: 'SET_REGISTRATION_ID',
            registrationId: response.registrationId,
            redirectedToPaysera: true,
          });

          dispatch({
            type: 'SET_BACK_URL',
            url: `/checkout`,
          });

          dispatch({
            type: 'ADD_TO_CART_PAYMENTURL',
            payseraUrl: payseraUrl,
          });

          window.location.replace(payseraUrl);
        }
      }
    });
  }

  const handleChildSelect = (child) => {
    const kid = userState?.contacts.find((x) => x.espbiEsi === child.espbiEsi);
    if (kid) {
      setSelectedChild(kid.name);
      setSelectedChildEspbiEsi(kid.espbiEsi);
      formRef.current.values.childName = kid.name;
      formRef.current.values.childSurname = kid.surname;
      formRef.current.values.childIdNumber = kid.personalCode;
      setChildSelected(true);
    } else {
      setChildSelected(false);
      setSelectedChild(child);
      formRef.current.values.childName = child;
    }
  };

  // useEffect(() => {
  //   if (userState?.contacts) {
  //     const tmp = [];
  //     userState?.contacts.forEach((kid) => {
  //       if (kid.espbiEsi) {
  //         const joinedKid = `${kid.name} ${kid.surname} (${kid.personalCode})`;
  //         tmp.push(joinedKid);
  //       }
  //     });
  //     setKids(tmp);
  //   }
  // }, [userState?.contacts]);

  const handleTotalCoupons = (coupons) => {
    let sum = 0;
    formRefCoupons.current.values.coupons.forEach((coupon) => {
      if (coupon.couponBalance) {
        sum += coupon.couponBalance;
      }
    });
    setTotalCouponsApplied(sum);
  };

  const isRemote = () => {
    return !!cartItems.find((item) => item.isServiceRemote);
  };

  const isPaid = () => {
    return cartItems.find((item) => item.orderData?.timeSlot?.isPaid);
  };

  const isPaidCoupons = () => {
    return (
      cartItems.find((item) => item.orderData?.timeSlot?.isPaid) ||
      cartItems[0].additionalServices.length > 0
    );
  };

  const validationSchemaForNotLoggedInUser = (remote, shouldValidatePrivateSocialPaymentModal) => {
    const schema = {
      patientName: Yup.string()
        .required(t('validation.requiredField'))
        .test(
          'confirmName',
          'Vardo formatas netinkamas',
          function (value, { createError }) {
            const nameRegex = /^\s+|\s+$/g;
            let result = nameRegex.test(value);
            return !result;
          },
        ),
      patientSurname: Yup.string()
        .required(t('validation.requiredField'))
        .test(
          'confirmSurname',
          'Pavardės formatas netinkamas',
          function (value, { createError }) {
            const surnameRegex = /^\s+|\s+$/g;
            let result = surnameRegex.test(value);
            return !result;
          },
        ),
      patientEmail: Yup.string()
        .email(t('validation.invalidFormat'))
        .required(t('validation.requiredField'))
        .test(
          'confirmEmail',
          t('validation.checkEmail'),
          function (value, { createError }) {
            const sideSpaceRegex = /^\s+|\s+$/g;
            let result = sideSpaceRegex.test(value);
            if (!result) {
              return !result;
            }
            let validEmail = validateEmail(value);
            return validEmail;
          },
        ),
      patientPhoneNumber: Yup.string()
        .required(t('validation.requiredField'))
        .test(
          'phone',
          'Telefono numerio formatas netinkamas',
          (val, { createError }) => {
            if (val === '') {
              return createError({
                message: t('Įveskite telefono numerį'),
                path: 'patientPhoneNumber',
              });
            }
            if (val) {
              let splittedValue = val.split('');
              if (splittedValue.some((v) => v === ' ')) {
                return false;
              }
            }
            var phone = validatePhoneNumber(val);

            if (!phone.valid) {
              return createError({
                message: t('Netinkamas telefono numeris'),
                path: 'patientPhoneNumber',
              });
            } else if (!phone.lt) {
              return true;
            } else if (phone.valid && phone.format && phone.changed) {
              formRef.current.values.patientPhoneNumber = phone.newValue;
              saveValidationLog(
                `?changedFrom=${phone.oldVal}&changedTo=${phone.newValue}`,
              );
              return true;
            } else if (
              phone.valid &&
              phone.format &&
              phone.orgVal &&
              phone.newVal &&
              !phone.showModal
            ) {
              formRef.current.values.patientPhoneNumber = phone.newVal;

              saveValidationLog(
                `?changedFrom=${phone.orgVal}&changedTo=${phone.newVal}`,
              );
              return true;
            } else if (
              phone.valid &&
              phone.format &&
              phone.orgVal &&
              phone.newVal
            ) {
              setValidPhoneNumber(phone.newVal);
              setInvalidPhoneNumber(phone.orgVal);
              setShowPhoneValidationModal(true);
            }
          },
        ),
      patientIdNumber: Yup.string()
        .required(t('validation.requiredField'))
        .trim()
        .test('len', (val, { createError }) => {
          const validatedPersonalCode = validatePersonalCode(
            val?.replace(/\s/g, ''),
          );
          const testResult = maxAgeFromPersonalCode(
            val?.replace(/\s/g, ''),
            18,
          );
          if (!validatedPersonalCode) {
            return createError({
              message: t('validation.personalCode'),
              path: 'patientIdNumber',
            });
          }
          if (!testResult) {
            setUnderageModalVisibile(true);
            return createError({
              message: t('Asmuo nepilnametis'),
              path: 'patientIdNumber',
            });
          }
          return true;
        }),
      agreement2: Yup.bool().oneOf([true], t('validation.requiredField')),
      agreement3: Yup.bool().oneOf([true], t('validation.requiredField')),
      otherPerson: Yup.boolean(),
      childName: Yup.string().when('otherPerson', {
        is: true,
        then: Yup.string().required(requiredMessage),
      }),
      childSurname: Yup.string().when('otherPerson', {
        is: true,
        then: Yup.string().required(requiredMessage),
      }),
      childIdNumber: Yup.string().when('otherPerson', {
        is: true,
        then: Yup.string()
          .required(t('validation.requiredField'))
          .trim()
          .test('len', (val, { createError }) => {
            const validatedPersonalCode = validatePersonalCode(val);
            if (!validatedPersonalCode) {
              return createError({
                message: t('validation.personalCode'),
                path: 'childIdNumber',
              });
            }
            if (cartItems[0].isChildrenAgeRestrictionApplicable) {
              const testResultMax = maxAgeFromPersonalCode(
                val,
                cartItems[0].childAgeTo ?? 18,
              );
              const testResultMin = minAgeFromPersonalCode(
                val,
                cartItems[0].childAgeFrom,
              );
              if (testResultMax || testResultMin) {
                return createError({
                  message: t(
                    `Gydytojas konsultuoja tik vaikus nuo ${cartItems[0].childAgeFrom} metų`,
                  ),
                  path: 'childIdNumber',
                });
              }
            } else {
              const testResult = maxAgeFromPersonalCode(val, 18);
              if (testResult) {
                return createError({
                  message: t('Įveskite vaiko iki 18 metų asmens kodą'),
                  path: 'childIdNumber',
                });
              }
            }
            return true;
          }),
      }),
      termsAndConditions: Yup.bool().when('otherPerson', {
        is: true,
        then: Yup.bool().oneOf([true], requiredMessage),
      }),

      insuranceCompany: Yup.string()
        .trim()
        .test('insurance-company-validation-tests', (val, { createError }) => {
          if (!shouldValidatePrivateSocialPaymentModal) {
            return true;
          }

          if (!val) {
            return createError({
              message: t('validation.requiredField'),
              path: 'insuranceCompany',
            });
          }

          return true;

        }),

      insuranceNumber: Yup.string()
        .trim()
        .test('insurance-number-validation-tests', (val, { createError, parent }) => {
          if (!shouldValidatePrivateSocialPaymentModal) {
            return true;
          }

          if (!val) {
            return createError({
              message: t('validation.requiredField'),
              path: 'insuranceNumber',
            });
          }

          const wrongNumberError = createError({
            message: "Klaidingas kortelės numeris",
            path: 'insuranceNumber',
          });

          switch (parent.insuranceCompany) {
            case "Ergo":
              if (!/^[A-Za-z]{3}\d{6}$/.test(val)) {  // 3 letters following 6 numbers
                return wrongNumberError;
              }
              break;

            case "Gjensidige":
              if (!/^\d{10}$/.test(val)) {            // 10 numbers
                return wrongNumberError;
              }
              break;

            default:                                  // for the remaining companies
              if (!/^\d{19}$/.test(val)) {            // 19 numbers 
                return wrongNumberError;
              }
              break;
          }

          return true;

        }),
    };
    if (remote) {
      schema.agreement1 = Yup.bool().oneOf(
        [true],
        t('validation.requiredField'),
      );
    }
    return Yup.object().shape(schema);
  };

  useEffect(() => {
    if (cartItems[0]?.isChildrenOnly) {
      formRef.current.setFieldValue('otherPerson', true);
    }
  }, [cartItems]);

  const validationSchemaForLoggedInUser = (remote, shouldValidatePrivateSocialPaymentModal) => {
    const schema = {
      patientName: Yup.string().required(t('validation.requiredField')).trim(),
      patientSurname: Yup.string()
        .required(t('validation.requiredField'))
        .trim(),
      patientEmail: Yup.string()
        .email(t('validation.invalidFormat'))
        .trim()
        .required(t('validation.requiredField')),
      patientIdNumber: Yup.string()
        .trim()
        .test('len', (val, { createError }) => {
          if (userState.containsPersonalCode) {
            return true;
          }
          const validatedPersonalCode = validatePersonalCode(
            val?.replace(/\s/g, ''),
          );
          const testResult = maxAgeFromPersonalCode(
            val?.replace(/\s/g, ''),
            18,
          );
          if (!validatedPersonalCode) {
            return createError({
              message: t('validation.personalCode'),
              path: 'patientIdNumber',
            });
          }
          if (!testResult) {
            setUnderageModalVisibile(true);
            return createError({
              message: t('Asmuo nepilnametis'),
              path: 'patientIdNumber',
            });
          }

          return true;
        }),
      patientPhoneNumber: Yup.string()
        .required(t('validation.requiredField'))
        .trim(),
      agreement2: Yup.bool().oneOf([true], t('validation.requiredField')),
      agreement3: Yup.bool().oneOf([true], t('validation.requiredField')),

      otherPerson: Yup.boolean(),
      childName: Yup.string().when('otherPerson', {
        is: true,
        then: Yup.string().required(requiredMessage),
      }),
      childSurname: Yup.string().when('otherPerson', {
        is: true,
        then: Yup.string().required(requiredMessage),
      }),
      childIdNumber: Yup.string().when('otherPerson', {
        is: true,
        then: Yup.string()
          .required(t('validation.requiredField'))
          .trim()
          .test('len', (val, { createError }) => {
            const validatedPersonalCode = validatePersonalCode(val);
            if (!validatedPersonalCode) {
              return createError({
                message: t('validation.personalCode'),
                path: 'childIdNumber',
              });
            }
            if (cartItems[0].isChildrenAgeRestrictionApplicable) {
              const testResultMax = maxAgeFromPersonalCode(
                val,
                cartItems[0].childAgeTo ?? 18,
              );
              const testResultMin = minAgeFromPersonalCode(
                val,
                cartItems[0].childAgeFrom,
              );
              if (testResultMax || testResultMin) {
                return createError({
                  message: t(
                    `Gydytojas konsultuoja tik vaikus nuo ${cartItems[0].childAgeFrom} metų`,
                  ),
                  path: 'childIdNumber',
                });
              }
            } else {
              const testResult = maxAgeFromPersonalCode(val, 18);
              if (testResult) {
                return createError({
                  message: t('Įveskite vaiko iki 18 metų asmens kodą'),
                  path: 'childIdNumber',
                });
              }
            }
            return true;
          }),
      }),
      termsAndConditions: Yup.bool().when('otherPerson', {
        is: true,
        then: Yup.bool().oneOf([true], requiredMessage),
      }),

      insuranceCompany: Yup.string()
        .trim()
        .test('insurance-company-validation-tests', (val, { createError }) => {
          if (!shouldValidatePrivateSocialPaymentModal) {
            return true;
          }

          if (!val) {
            return createError({
              message: t('validation.requiredField'),
              path: 'insuranceCompany',
            });
          }

          return true;

        }),

      insuranceNumber: Yup.string()
        .trim()
        .test('insurance-number-validation-tests', (val, { createError, parent }) => {
          if (!shouldValidatePrivateSocialPaymentModal) {
            return true;
          }

          if (!val) {
            return createError({
              message: t('validation.requiredField'),
              path: 'insuranceNumber',
            });
          }

          const wrongNumberError = createError({
            message: "Klaidingas kortelės numeris",
            path: 'insuranceNumber',
          });

          switch (parent.insuranceCompany) {
            case "Ergo":
              if (!/^[A-Za-z]{3}\d{6}$/.test(val)) {  // 3 letters following 6 numbers
                return wrongNumberError;
              }
              break;

            case "Gjensidige":
              if (!/^\d{10}$/.test(val)) {            // 10 numbers
                return wrongNumberError;
              }
              break;

            default:                                  // for the remaining companies
              if (!/^\d{19}$/.test(val)) {            // 19 numbers 
                return wrongNumberError;
              }
              break;
          }

          return true;

        }),
    };

    if (remote) {
      schema.agreement1 = Yup.bool().oneOf(
        [true],
        t('validation.requiredField'),
      );
    }
    return Yup.object().shape(schema);
  };

  const validationSchema = (remote, shouldValidatePrivateSocialPaymentModal) => {
    return userState.isLoggedIn && userState.isLoggedIn !== null
      ? validationSchemaForLoggedInUser(remote, shouldValidatePrivateSocialPaymentModal)
      : validationSchemaForNotLoggedInUser(remote, shouldValidatePrivateSocialPaymentModal);
  };
  if (!products.length && !isFormSubmitting) {
    // return <Redirect to="/" />;
  }

  if (request.loading) {
    return (
      <Page>
        <Loader />
      </Page>
    );
  }

  const handleAllCartItemsRemove = async () => {
    dispatch({
      type: 'REMOVE_ORDER_URL',
    });
    return await Promise.all(
      cartItems.map(
        async (service, i) =>
          await handleOneCartItemRemove(
            service.orderData.id,
            service.orderData.timeSlotId,
          ),
      ),
    );
  };

  const handleOneCartItemRemove = async (id, timeSlotId) => {
    dispatch({ type: 'REMOVE_FROM_CART', id });
  };

  const handleDataFromChild = (data) => {
    setPriceFromSummary(data);
  };

  const removeDiscountPrices = () => {
    const updatedCartItems = cartItems.map((item) => ({
      ...item,
      priceWithDiscountCode: null,
      discountCode: null,
      additionalServices: item.additionalServices.map((additionalService) => ({
        ...additionalService,
        priceWithDiscountCode: null,
        discountCode: null,
      })),
    }));

    setCartItems(updatedCartItems);
  };


  return (
    <Page>
      <Container>
        <Page.Title
          backUrl={history.location.state.backPath}
          backData={{
            lastStep: step,
            registrationServices: products,
            resetStorage: false,
          }}
        >
          {t('cart.title')}
        </Page.Title>
        {!!cartItems.length ? (
          <>
            <Summary
              items={cartItems}
              couponSum={totalCouponsApplied}
              onRemove={async (id, timeSlotId) => {
                await handleOneCartItemRemove(id, timeSlotId);
                history.push('/');
              }}
              totalPrice={(s) => {
                setTotalPrice(s);
              }}
              totalInitialPrice={(s) => {
                setTotalInitialSum(s);
              }}
              isMarginBottom={true}
              onDataFromChild={handleDataFromChild}
            />

            {isPaidCoupons() && (
              <Styled.CouponFieldsParent>
                <Styled.CouponFields active={true}>
                  <Formik
                    innerRef={formRefDiscount}
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                      discount: {
                        discountCode: '',
                        couponSecondaryLabel: false,
                        couponLabel: false,
                        inputVisible: true,
                      },
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      setFieldError,
                      setFieldValue,
                    }) => (
                      <>
                        <Form>
                          <Form.Group>
                            {isPaidCoupons() && (
                              <Styled.CouponCheckbox>
                                <Checkbox
                                  showBorder={true}
                                  value={showDiscountCoupon}
                                  checked={showDiscountCoupon}
                                  onChange={(e) => {
                                    setShowDiscountCoupon(e.target.checked);
                                    if (!e.target.checked) {
                                      setFieldError(
                                        `discount.discountCode`,
                                        '',
                                      );
                                      removeDiscountPrices();
                                      values.discount.discountCode = '';
                                      values.discount.couponSecondaryLabel =
                                        false;
                                      values.discount.couponLabel = false;
                                      values.discount.inputVisible = true;
                                      setDiscountCodeNumber('');
                                    }
                                  }}
                                  innerRef={checkBoxRef}
                                >
                                  <Styled.Trans>
                                    <Trans i18nKey="cart.discountCodeCheckbox" />
                                  </Styled.Trans>
                                </Checkbox>
                              </Styled.CouponCheckbox>
                            )}
                            <div>
                              {showDiscountCoupon && (
                                <Styled.MainContainerDiv message={false}>
                                  {values.discount.inputVisible && (
                                    <Form.Row>
                                      <Styled.CouponFieldsContainer>
                                        <Field
                                          focus={true}
                                          type={'text'}
                                          component={TextField}
                                          label={t(
                                            'cart.fieldDiscountCodeLabel',
                                          )}
                                          name={`discount.discountCode`}
                                          value={
                                            values.discount.discountCode || ''
                                          }
                                          onChange={handleChange}
                                        />
                                        <Styled.CouponButtonContainer>
                                          <Button
                                            size="large"
                                            onClick={() => {
                                              let dc =
                                                values.discount.discountCode.toString();

                                              if (dc === '') {
                                                setFieldError(
                                                  `discount.discountCode`,
                                                  t('coupons.couponEmpty'),
                                                );
                                                return;
                                              }

                                              Promise.all(
                                                cartItems.flatMap((item) => {
                                                  const primaryPromise =
                                                    checkDiscountCode({
                                                      discountCode:
                                                        dc.toString(),
                                                      serviceId: item.serviceId,
                                                      departmentId:
                                                        item.departmentId,
                                                      visitType: item?.orderData
                                                        ?.timeSlot?.isExtra
                                                        ? 'Extra'
                                                        : !item?.orderData
                                                          ?.primaryVisit
                                                          ? 'Secondary'
                                                          : 'Main',
                                                    });

                                                  const additionalPromises =
                                                    item.additionalServices?.map(
                                                      (addServ) => {
                                                        return checkDiscountCode(
                                                          {
                                                            discountCode:
                                                              dc.toString(),
                                                            serviceId:
                                                              addServ.serviceId,
                                                            departmentId:
                                                              item.departmentId,
                                                            visitType: 'Main',
                                                          },
                                                        );
                                                      },
                                                    ) || [];

                                                  return [
                                                    primaryPromise,
                                                    ...additionalPromises,
                                                  ];
                                                }),
                                              )
                                                .then((data) => {
                                                  if (
                                                    !data ||
                                                    data.some(
                                                      (result) =>
                                                        result.status ===
                                                        'rejected',
                                                    )
                                                  ) {
                                                    setFieldError(
                                                      `discount.discountCode`,
                                                      'Įvyko klaida bandant pritaikytį nuolaidos kodą',
                                                    );
                                                    return;
                                                  }
                                                  const discounts = data;
                                                  if (
                                                    discounts.every(
                                                      (item) =>
                                                        !item.isDiscountFound,
                                                    )
                                                  ) {
                                                    setFieldError(
                                                      `discount.discountCode`,
                                                      t(
                                                        'coupons.discountCodeNotFound',
                                                      ),
                                                    );
                                                    return;
                                                  }

                                                  if (
                                                    discounts.every(
                                                      (item) =>
                                                        !item.isApplicableByDate,
                                                    )
                                                  ) {
                                                    setFieldError(
                                                      `discount.discountCode`,
                                                      'Nuolaidos kodas negalioja',
                                                    );
                                                    return;
                                                  }

                                                  if (
                                                    discounts.length === 1 &&
                                                    discounts[0].price >=
                                                    totalInitialSum
                                                  ) {
                                                    if (
                                                      cartItems[0].discountType
                                                    ) {
                                                      setFieldError(
                                                        `discount.discountCode`,
                                                        `Jums pritaikyta "${cartItems[0].discountType}" nuolaida. Nuolaidos nesumuojamos.`,
                                                      );
                                                    } else {
                                                      setFieldError(
                                                        `discount.discountCode`,
                                                        `Jums jau pritaikyta didesnė nuolaida. Nuolaidos nesumuojamos.`,
                                                      );
                                                    }

                                                    return;
                                                  }

                                                  setCartItems((currentItems) =>
                                                    currentItems.map((item) => {
                                                      // Update the main item with its discount
                                                      const itemDiscount =
                                                        discounts.find(
                                                          (discount) =>
                                                            discount.serviceId ===
                                                            item.serviceId,
                                                        );
                                                      const updatedItem =
                                                        itemDiscount
                                                          ? {
                                                            ...item,
                                                            isDiscountFound:
                                                              itemDiscount.isDiscountFound,
                                                            priceWithDiscountCode:
                                                              itemDiscount.price,
                                                            discountCode:
                                                              itemDiscount.discountCode,
                                                          }
                                                          : item;

                                                      // Update additional services with their discounts
                                                      if (
                                                        updatedItem.additionalServices
                                                      ) {
                                                        updatedItem.additionalServices =
                                                          updatedItem.additionalServices.map(
                                                            (service) => {
                                                              const serviceDiscount =
                                                                discounts.find(
                                                                  (discount) =>
                                                                    discount.serviceId ===
                                                                    service.serviceId,
                                                                );
                                                              return serviceDiscount
                                                                ? {
                                                                  ...service,
                                                                  isDiscountFound:
                                                                    serviceDiscount.isDiscountFound,
                                                                  priceWithDiscountCode:
                                                                    serviceDiscount.price,
                                                                  discountCode:
                                                                    serviceDiscount.discountCode,
                                                                }
                                                                : service;
                                                            },
                                                          );
                                                      }

                                                      return updatedItem;
                                                    }),
                                                  );

                                                  setFieldError(
                                                    `discount.discountCode`,
                                                    '',
                                                  );
                                                  setFieldValue(
                                                    `discount.couponLabel`,
                                                    true,
                                                  );
                                                  setFieldValue(
                                                    `discount.inputVisible`,
                                                    false,
                                                  );
                                                  setDiscountCodeNumber(dc);
                                                })
                                                .catch((error) => {
                                                  setFieldError(
                                                    `discount.discountCode`,
                                                    `Įvyko klaida bandant pritaikytį nuolaidos kodą.`,
                                                  );
                                                  return;
                                                });
                                            }}
                                          >
                                            Pritaikyti
                                          </Button>
                                        </Styled.CouponButtonContainer>
                                      </Styled.CouponFieldsContainer>
                                    </Form.Row>
                                  )}
                                  {values.discount.couponLabel && (
                                    <Form.Row>
                                      <Styled.CouponFieldsContainer>
                                        <Styled.CouponParagraphContainer>
                                          <p>{`Nuolaidos kodas "${discountCodeNumber}" priimtas`}</p>
                                        </Styled.CouponParagraphContainer>
                                      </Styled.CouponFieldsContainer>
                                    </Form.Row>
                                  )}
                                </Styled.MainContainerDiv>
                              )}
                            </div>
                          </Form.Group>
                        </Form>
                      </>
                    )}
                  </Formik>
                </Styled.CouponFields>
              </Styled.CouponFieldsParent>
            )}

            {isPaidCoupons() && (
              <Styled.CouponFieldsParent>
                <Styled.CouponFields active={true}>
                  <Formik
                    innerRef={formRefCoupons}
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                      coupons: [
                        {
                          couponNumber: '',
                          couponSecondaryLabel: false,
                          couponBalance: 0,
                          couponLabel: false,
                          couponButton: false,
                          inputVisible: true,
                        },
                      ],
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      setFieldError,
                      setFieldValue,
                    }) => (
                      <>
                        <Form>
                          <Form.Group>
                            {isPaidCoupons() && (
                              <Styled.CouponCheckbox>
                                <Checkbox
                                  showBorder={true}
                                  value={showGiftCoupon}
                                  checked={showGiftCoupon}
                                  onChange={(e) => {
                                    setShowGiftCoupon(e.target.checked);
                                    if (!e.target.checked) {
                                      setAppliedCouponNumbers((prev) =>
                                        prev.filter((cn) => false),
                                      );
                                    }
                                  }}
                                  innerRef={checkBoxRef}
                                >
                                  <Styled.Trans>
                                    <Trans i18nKey="cart.couponCheckbox" />
                                  </Styled.Trans>
                                </Checkbox>
                              </Styled.CouponCheckbox>
                            )}
                            {showGiftCoupon && (
                              <FieldArray
                                name="coupons"
                                render={(arrayHelpers) => (
                                  <div>
                                    {values.coupons.map((coupon, index) => {
                                      return (
                                        <Styled.MainContainerDiv
                                          message={false}
                                        >
                                          {values.coupons[index]
                                            .inputVisible && (
                                              <Form.Row>
                                                <Styled.CouponFieldsContainer>
                                                  <Field
                                                    focus={true}
                                                    type={'text'}
                                                    component={TextField}
                                                    label={t(
                                                      'cart.fieldCouponLabel',
                                                    )}
                                                    name={`coupons.${index}.couponNumber`}
                                                    value={
                                                      values.coupons[index]
                                                        .couponNumber || ''
                                                    }
                                                    onChange={handleChange}
                                                  />
                                                  <Styled.CouponButtonContainer>
                                                    <Button
                                                      size="large"
                                                      onClick={async (value) => {
                                                        let cp =
                                                          values.coupons[
                                                            index
                                                          ].couponNumber.toString();

                                                        setFieldValue(
                                                          `coupons[${index}].couponSecondaryLabel`,
                                                          false,
                                                        );

                                                        if (
                                                          appliedCouponNumbers.includes(
                                                            cp,
                                                          )
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponUsed',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        if (totalPrice <= 0) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.overPrice',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        const response =
                                                          await checkCoupon(' ', {
                                                            couponNumber:
                                                              cp.toString(),
                                                            serviceId: serviceId,
                                                            departmentId:
                                                              cartItems[0]
                                                                .departmentExternalId,
                                                          });

                                                        if (
                                                          checkCouponResponse.status ===
                                                          400
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponNotFound',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        if (
                                                          checkCouponResponse.status >
                                                          199
                                                        ) {
                                                          if (
                                                            response.rejectReason ==
                                                            'Expired'
                                                          ) {
                                                            setFieldError(
                                                              `coupons[${index}].couponNumber`,
                                                              t(
                                                                'coupons.couponExpired',
                                                              ),
                                                            );
                                                            return;
                                                          }

                                                          if (
                                                            response.rejectReason ==
                                                            'Empty'
                                                          ) {
                                                            setFieldError(
                                                              `coupons[${index}].couponNumber`,
                                                              t(
                                                                'coupons.couponBalanceEmpty',
                                                              ),
                                                            );
                                                            return;
                                                          }

                                                          if (
                                                            response.rejectReason ==
                                                            'Restricted'
                                                          ) {
                                                            setFieldError(
                                                              `coupons[${index}].couponNumber`,
                                                              t(
                                                                'coupons.couponOtherService',
                                                              ),
                                                            );
                                                            return;
                                                          }

                                                          if (
                                                            response.rejectReason ==
                                                            'CompanyMismatch'
                                                          ) {
                                                            setFieldError(
                                                              `coupons[${index}].couponNumber`,
                                                              t(
                                                                'coupons.couponCompany',
                                                              ),
                                                            );
                                                            return;
                                                          }

                                                          if (
                                                            response.statusId ===
                                                            'Rezervuotas'
                                                          ) {
                                                            setFieldError(
                                                              `coupons[${index}].couponNumber`,
                                                              t(
                                                                'coupons.couponReserved',
                                                              ),
                                                            );
                                                            return;
                                                          }

                                                          if (
                                                            response.statusId ===
                                                            'Darbinis'
                                                          ) {
                                                            setFieldError(
                                                              `coupons[${index}].couponNumber`,
                                                              t(
                                                                'coupons.couponOther',
                                                              ),
                                                            );
                                                            return;
                                                          }

                                                          if (
                                                            response.statusId ===
                                                            'Nebegalioja'
                                                          ) {
                                                            setFieldError(
                                                              `coupons[${index}].couponNumber`,
                                                              t(
                                                                'coupons.couponExpired',
                                                              ),
                                                            );
                                                            return;
                                                          }
                                                          if (
                                                            response.statusId ===
                                                            'Panaudotas'
                                                          ) {
                                                            setFieldError(
                                                              `coupons[${index}].couponNumber`,
                                                              t(
                                                                'coupons.couponSingleUsed',
                                                              ),
                                                            );
                                                            return;
                                                          }
                                                          if (
                                                            response.statusId ===
                                                            'Anuliuotas'
                                                          ) {
                                                            setFieldError(
                                                              `coupons[${index}].couponNumber`,
                                                              t(
                                                                'coupons.couponCancelled',
                                                              ),
                                                            );
                                                            return;
                                                          }
                                                          if (
                                                            response.statusId !==
                                                            'Galioja'
                                                          ) {
                                                            setFieldError(
                                                              `coupons[${index}].couponNumber`,
                                                              t(
                                                                'coupons.couponNonApplicable',
                                                              ),
                                                            );
                                                            return;
                                                          }
                                                        }

                                                        if (cp === '') {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponEmpty',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        setAppliedCouponNumbers(
                                                          (prev) => [...prev, cp],
                                                        );

                                                        setFieldError(
                                                          `coupons[${index}].couponNumber`,
                                                          '',
                                                        );
                                                        setFieldValue(
                                                          `coupons[${index}].couponLabel`,
                                                          true,
                                                        );
                                                        setFieldValue(
                                                          `coupons[${index}].couponBalance`,
                                                          response.balance,
                                                        );
                                                        setFieldValue(
                                                          `coupons[${index}].couponButton`,
                                                          true,
                                                        );
                                                        setFieldValue(
                                                          `coupons[${index}].inputVisible`,
                                                          false,
                                                        );
                                                        setChangeCount(
                                                          (prev) => prev + 1,
                                                        );
                                                      }}
                                                    >
                                                      Pritaikyti
                                                    </Button>
                                                  </Styled.CouponButtonContainer>
                                                </Styled.CouponFieldsContainer>
                                              </Form.Row>
                                            )}
                                          {values.coupons[index]
                                            .couponLabel && (
                                              <Form.Row>
                                                <Styled.CouponFieldsContainer>
                                                  <Styled.CouponParagraphContainer>
                                                    <p>
                                                      {' '}
                                                      {`Dovanų kuponas Nr.${values.coupons[index].couponNumber}, vertė
                                                  ${values.coupons[index].couponBalance}€`}
                                                    </p>
                                                  </Styled.CouponParagraphContainer>
                                                  <Styled.CouponButtonContainer>
                                                    <Button
                                                      size="large"
                                                      type="secondary"
                                                      style={{
                                                        backgroundColor: '#fff',
                                                        color: '#000',
                                                      }}
                                                      onClick={() => {
                                                        arrayHelpers.remove(
                                                          index,
                                                        );
                                                        setChangeCount(
                                                          (prev) => prev + 1,
                                                        );

                                                        setAppliedCouponNumbers(
                                                          (prev) =>
                                                            prev.filter(
                                                              (cp) =>
                                                                cp !==
                                                                values.coupons[
                                                                  index
                                                                ].couponNumber,
                                                            ),
                                                        );
                                                      }}
                                                    >
                                                      Pašalinti
                                                    </Button>
                                                  </Styled.CouponButtonContainer>
                                                </Styled.CouponFieldsContainer>
                                              </Form.Row>
                                            )}
                                        </Styled.MainContainerDiv>
                                        //end of main div
                                      );
                                    })}
                                    {values.coupons &&
                                      values.coupons[values.coupons.length - 1]
                                        ?.couponButton && (
                                        <Form.Row>
                                          <Button
                                            size="large"
                                            type="secondary"
                                            onClick={() => {
                                              arrayHelpers.insert(
                                                values.coupons.length + 1,
                                                {
                                                  couponNumber: '',
                                                  couponLabel: false,
                                                  inputVisible: true,
                                                },
                                              );
                                            }}
                                          >
                                            Pridėti dar vieną dovanų kuponą
                                          </Button>
                                        </Form.Row>
                                      )}
                                  </div>
                                )}
                              />
                            )}
                          </Form.Group>
                        </Form>
                      </>
                    )}
                  </Formik>
                </Styled.CouponFields>
              </Styled.CouponFieldsParent>
            )}

            <Tabs active={products[0]?.activeTabs?.length} marginTop={true}>
              <Tabs.List>
                {products[0]?.activeTabs?.map((tab) =>
                  <Tabs.Tab key={tab}>{tab}</Tabs.Tab>
                )}
                <Tabs.Tab key={t('cart.tab1Name')}>{t('cart.tab1Name')}</Tabs.Tab>
                <Tabs.Tab key={showPsdfTabName ? t('cart.tab2Psdf') : t('cart.tab2Name')}>
                  {showPsdfTabName ? t('cart.tab2Psdf') : t('cart.tab2Name')}
                </Tabs.Tab>
              </Tabs.List>

              {products[0]?.activeTabs?.map((tab) =>
                <Tabs.Content key={tab}></Tabs.Content>
              )}
              <Tabs.Content key={t('cart.tab1Name')}>
                <Formik
                  innerRef={formRef}
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={{
                    patientName: userState.firstName || '',
                    patientSurname: userState.lastName || '',
                    patientEmail: userState.email || '',
                    patientPhoneNumber: userState.phoneNumber || '',
                    patientIdNumber: userState.containsPersonalCode
                      ? userState.personalCode
                      : '',
                    agreement1: false,
                    agreement2: false,
                    agreement3: false,
                    otherPerson: false,
                    childName: userState.childName || '',
                    childSurname: userState.childSurname || '',
                    childIdNumber: userState.childIdNumber || '',
                    insuranceNumber: userState.insuranceCardNumber || '',
                    insuranceCompany: userState.insuranceCompanyName || '',
                  }}
                  validationSchema={validationSchema(isRemote(), showPrivateSocialPaymentModal)}
                  onSubmit={handleFormSubmit}
                >
                  {({
                    values,
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    errors,
                    setFieldValue,
                  }) => (
                    <>
                      <Form>
                        <Form.Group>
                          {!cartItems[0]?.isAdultsOnly && (
                            <>
                              <Form.Row>
                                <Checkbox
                                  name="otherPerson"
                                  checked={values.otherPerson}
                                  onChange={() => {
                                    if (cartItems[0]?.isChildrenOnly) {
                                      setShowChildAgeRestrictionModal(true);
                                    } else {
                                      if (values.otherPerson) {
                                        [
                                          'childName',
                                          'childSurname',
                                          'childBirthDate',
                                          'childIdNumber',
                                        ].forEach((key) => {
                                          setFieldValue(key, '');
                                        });
                                      }
                                      setFieldValue(
                                        'otherPerson',
                                        !values.otherPerson,
                                      );
                                    }
                                  }}
                                >
                                  {cartItems[0]
                                    .isChildrenAgeRestrictionApplicable &&
                                    cartItems[0].childAgeFrom
                                    ? t(
                                      'registration.form.fieldChildAgeFromToCheckbox',
                                      {
                                        ageFrom: cartItems[0].childAgeFrom,
                                        ageTo: cartItems[0].childAgeTo ?? 18,
                                      },
                                    )
                                    : t('registration.form.fieldChildCheckbox')}
                                </Checkbox>
                              </Form.Row>
                              <Form.Title>
                                {t('cart.patientGroupLabel')}
                              </Form.Title>
                              {values.otherPerson && (
                                <Form indent>
                                  <Form.Row>
                                    {userState?.isLoggedIn && (
                                      <Field
                                        component={ChildDropdown}
                                        onChildSelect={handleChildSelect}
                                        name="childName"
                                        value={values.childName}
                                        availableKids={kids}
                                        label={t(
                                          'registration.form.fieldNameLabel',
                                        )}
                                        onChange={handleChange}
                                        error={errors.childName}
                                      />
                                    )}

                                    {!userState?.isLoggedIn && (
                                      <Field
                                        component={TextField}
                                        name="childName"
                                        value={values.childName}
                                        label={t(
                                          'registration.form.fieldNameLabel',
                                        )}
                                        onChange={handleChange}
                                        error={errors.childName}
                                      />
                                    )}

                                    <Field
                                      component={TextField}
                                      name="childSurname"
                                      value={values.childSurname}
                                      label={t(
                                        'registration.form.fieldSurnameLabel',
                                      )}
                                      onChange={handleChange}
                                      error={errors.childSurname}
                                    />

                                    <Field
                                      component={TextField}
                                      label={t('cart.fieldCodeLabel')}
                                      name="childIdNumber"
                                      value={values.childIdNumber}
                                      error={errors.childIdNumber}
                                      onChange={handleChange}
                                    />
                                  </Form.Row>
                                </Form>
                              )}
                            </>
                          )}
                          <Form.Group>
                            {values.otherPerson && (
                              <Form.Title>
                                {t('cart.buyerGroupLabel')}
                              </Form.Title>
                            )}
                            <Form.Row>
                              <Field
                                focus={true}
                                component={TextField}
                                label={t('cart.fieldNameLabel')}
                                name="patientName"
                                error={errors.patientName}
                                value={values.patientName}
                                disabled={userState.isLoggedIn}
                                onChange={handleChange}
                              />
                              <Field
                                component={TextField}
                                label={t('cart.fieldSurnameLabel')}
                                name="patientSurname"
                                error={errors.patientSurname}
                                value={values.patientSurname}
                                disabled={userState.isLoggedIn}
                                onChange={handleChange}
                              />
                            </Form.Row>
                          </Form.Group>
                          <Form.Row>
                            <Field
                              component={TextField}
                              label={t('cart.fieldCodeLabel')}
                              name="patientIdNumber"
                              error={errors.patientIdNumber}
                              value={values.patientIdNumber?.replace(/\s/g, '')}
                              disabled={
                                userState.isLoggedIn &&
                                  userState.containsPersonalCode
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <div />
                          </Form.Row>
                        </Form.Group>
                        <Form.Group>
                          <Form.Title>{t('cart.contactGroupLabel')}</Form.Title>
                          <Form.Row>
                            <Form.Item>
                              <Field
                                component={TextField}
                                label={t('cart.fieldPhoneLabel')}
                                name="patientPhoneNumber"
                                error={errors.patientPhoneNumber}
                                value={values.patientPhoneNumber}
                                disabled={userState.isLoggedIn}
                                onChange={handleChange}
                              />
                            </Form.Item>
                            <Form.Item>
                              <Field
                                component={TextField}
                                label={t('cart.fielEmailLabel')}
                                name="patientEmail"
                                error={errors.patientEmail}
                                value={values.patientEmail}
                                disabled={userState.isLoggedIn}
                                onChange={handleChange}
                              />
                            </Form.Item>
                          </Form.Row>
                        </Form.Group>
                        <Form.Group>
                          <Form.Title>
                            {t('cart.agreementGroulLabel')}
                          </Form.Title>
                          {isRemote() && (
                            <Form.Row>
                              <Checkbox
                                name="agreement1"
                                value="true"
                                onChange={handleChange}
                                error={errors.agreement1}
                              >
                                <Trans
                                  i18nKey="cart.fieldAgreement1Label"
                                  components={{
                                    a: (
                                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                                      <a
                                        href={agreements.rules.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      />
                                    ),
                                  }}
                                />
                              </Checkbox>
                            </Form.Row>
                          )}
                          <Form.Row>
                            <Checkbox
                              name="agreement2"
                              value="true"
                              onChange={handleChange}
                              error={errors.agreement2}
                            >
                              <Trans
                                i18nKey="cart.fieldAgreement2Label"
                                components={{
                                  a: (
                                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                                    <a
                                      href={agreements.personalData.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    />
                                  ),
                                }}
                              />
                            </Checkbox>
                          </Form.Row>
                          <Form.Row>
                            <Checkbox
                              name="agreement3"
                              value="true"
                              onChange={handleChange}
                              error={errors.agreement3}
                            >
                              <Trans
                                i18nKey="cart.fieldAgreement3Label"
                                components={{
                                  a: (
                                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                                    <a
                                      href={agreements.termsOfUse.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    />
                                  ),
                                }}
                              />
                            </Checkbox>
                          </Form.Row>
                          <Form.Row>
                            <p
                              style={{
                                fontWeight: 'bold',
                                fontSize: '13px',
                                margin: '0px',
                                marginTop: '-15px',
                                padding: '4px 0 4px 40px',
                                color: '#9395a9',
                              }}
                            >
                              {t('cart.fieldAgreement4Label')}
                            </p>
                          </Form.Row>
                        </Form.Group>
                        <Modal
                          visible={showPrivateSocialPaymentModal}
                          showClose={false}
                          onClose={() => {
                            setShowPrivateSocialPaymentModal(false);
                            formRef.current.values.insuranceCompany = '';
                            formRef.current.values.insuranceNumber = '';
                          }}
                        >
                          <div style={{
                            backgroundColor: '#f1f5f7',
                            padding: '10px',
                            paddingBottom: '40px',
                            borderRadius: '10px',
                            display: 'inline-block'
                          }}>
                            <Modal.TextContent>
                              Paspausdamas (-a) mygtuką „Patvirtinti rezervaciją“ aš patvirtinu ir garantuoju,
                              jog turiu ir asmens sveikatos priežiūros paslaugų teikimo metu turėsiu
                              galiojantį savanoriškąjį (papildomą) sveikatos ir/ar gyvybės draudimą
                              su pakankamu draudimo sumos likučiu.
                              Garantuoju, kad jei draudimo bendrovė neapmokės už man
                              suteiktas paslaugas arba sumokėtas sumas pareikalaus grąžinti,
                              aš pats apmokėsiu už suteiktas paslaugas per 5 (penkias)
                              kalendorines dienas nuo mokėjimo pranešimo išsiuntimo dienos.
                            </Modal.TextContent>

                            <Form.Group>
                              <Form.Row>
                                <SelectField
                                  error={errors.insuranceCompany}
                                  defaultValue={{ value: values.insuranceCompany, label: values.insuranceCompany }}
                                  name="insuranceCompany"
                                  label="Nurodykite draudimo įmonę"
                                  options={insuranceCompanies}
                                  onChange={(value) => {
                                    setFieldValue('insuranceCompany', value);
                                  }}
                                />
                              </Form.Row>
                              <Form.Row>
                                <Field
                                  component={TextField}
                                  name="insuranceNumber"
                                  value={values.insuranceNumber}
                                  label="Nurodykite draudimo kortelės numerį"
                                  onChange={handleChange}
                                  error={errors.insuranceNumber}
                                />
                              </Form.Row>
                            </Form.Group>
                          </div>
                          <Modal.Actions>
                            <Button
                              size="large"
                              variant="secondary"
                              onClick={() => {
                                setShowPrivateSocialPaymentModal(false);
                                formRef.current.values.insuranceCompany = '';
                                formRef.current.values.insuranceNumber = '';
                              }}
                            >
                              Atsisakyti
                            </Button>
                            <Button
                              size="large"
                              type="submit"
                              onClick={async () => {
                                await formRef.current.validateForm();
                                if (formRef.current.isValid) {
                                  setPrivateSocialPayment(true);
                                  formRef.current.handleSubmit();
                                  setShowPrivateSocialPaymentModal(false);
                                }
                              }}
                            >
                              Patvirtinti rezervaciją
                            </Button>
                          </Modal.Actions>
                        </Modal>
                      </Form>
                      <Form.Actions>
                        <Styled.FormButtons>
                          <Button
                            size="large"
                            variant="secondary"
                            onClick={() => {
                              handleAllCartItemsRemove();
                              history.push(history.location.state.backPath, {
                                lastStep: step,
                                registrationServices: products,
                                resetStorage: false,
                              });
                            }}
                          >
                            Atgal
                          </Button>
                          <Button
                            size="large"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            onClick={handleSubmit}
                          >
                            {isPaidCoupons() && totalPrice > 0
                              ? 'Eiti į apmokėjimą'
                              : 'Patvirtinti'}
                          </Button>

                          {isPaid() && (
                            <Button
                              style={{ marginRight: '20px' }}
                              size="large"
                              disabled={isSubmitting || totalCouponsApplied > 0}
                              loading={isSubmitting}
                              onClick={async () => {
                                await formRef.current.validateForm();
                                if (formRef.current.isValid) {
                                  setShowPrivateSocialPaymentModal(true);
                                }
                              }}
                            >
                              Apmokėti privačiu draudimu
                            </Button>
                          )}
                        </Styled.FormButtons>
                      </Form.Actions>
                    </>
                  )}

                </Formik>
              </Tabs.Content>
              <Tabs.Content key={showPsdfTabName ? t('cart.tab2Psdf') : t('cart.tab2Name')}></Tabs.Content>
            </Tabs>
          </>
        ) : (
          <NoResults>Prekių krepšelis tuščias</NoResults>
        )}
      </Container>
      <ErrorModal
        onClose={() => {
          setErrorModalVisible(false);
          dispatch({
            type: 'RESET_CART',
          });
          history.push(backPath);
        }}
        showError={errorModalVisible}
        errorModalMessage={errorModalMessage}
      />

      <Modal visible={underAge} showClose={false} onClose={() => { }}>
        {!cartItems[0]?.isAdultsOnly && (
          <>
            <Modal.TextContent>
              Nepilnamečius registruoti turi tėvai ar globėjai ir vaiko
              informaciją pateikti lange pažymėjus “Registruoju vaiką iki 18
              metų. Pereiti į vaiko registraciją?
            </Modal.TextContent>
            <Modal.Actions>
              <Button
                size="large"
                variant="secondary"
                onClick={() => {
                  setUnderageModalVisibile(false);
                }}
              >
                Atšaukti
              </Button>
              <Button
                size="large"
                onClick={() => {
                  const patientName = formRef.current.values.patientName;
                  const patientSurname = formRef.current.values.patientSurname;
                  const patientIdNumber =
                    formRef.current.values.patientIdNumber;

                  formRef.current.values.otherPerson = true;
                  formRef.current.values.childName = patientName;
                  formRef.current.values.childSurname = patientSurname;
                  formRef.current.values.childIdNumber = patientIdNumber;
                  formRef.current.values.patientName = '';
                  formRef.current.values.patientSurname = '';
                  formRef.current.values.patientIdNumber = '';
                  formRef.current.errors.patientIdNumber = '';
                  setUnderageModalVisibile(false);
                }}
              >
                Tęsti
              </Button>
            </Modal.Actions>
          </>
        )}
        {cartItems[0]?.isAdultsOnly && (
          <>
            <Modal.TextContent>
              Nepilnamečius registruoti turi tėvai ar globėjai kategorijoje
              Vaikų gydytojų specialistų konsultacijos. Pereiti į Vaikų gydytojų
              specialistų konsultacijos kategoriją
            </Modal.TextContent>
            <Modal.Actions>
              <Button
                size="large"
                variant="secondary"
                onClick={() => {
                  setUnderageModalVisibile(false);
                }}
              >
                Atšaukti
              </Button>
              <Button
                size="large"
                onClick={async () => {
                  let response = await getServiceTypeId(
                    `?serviceTypeExternalId=${vgskServiceTypeId}`,
                  );
                  history.push(
                    `/services/${response.categoryId}/${response.id}`,
                  );
                  setUnderageModalVisibile(false);
                }}
              >
                Tęsti
              </Button>
            </Modal.Actions>{' '}
          </>
        )}
      </Modal>

      <Modal
        visible={showPhoneValidationModal}
        showClose={false}
        onClose={() => {
          setShowPhoneValidationModal(false);
        }}
      >
        <Modal.TextContent>
          Nurodytas telefono numeris {invalidPhoneNumber} yra klaidingo formato.
          Pakeisti numerį į {validPhoneNumber}?
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            variant="secondary"
            onClick={() => {
              setShowPhoneValidationModal(false);
            }}
          >
            Atsisakyti
          </Button>
          <Button
            size="large"
            onClick={() => {
              formRef.current.values.patientPhoneNumber = validPhoneNumber;
              formRef.current.validateField('patientPhoneNumber');
              saveValidationLog(
                `?changedFrom=${invalidPhoneNumber}&changedTo=${validPhoneNumber}`,
              );
              setShowPhoneValidationModal(false);
            }}
          >
            Pakeisti
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={showChildAgeRestrictionModal}
        showClose={false}
        onClose={() => {
          setShowChildAgeRestrictionModal(false);
        }}
      >
        {cartItems[0]?.isChildrenAgeRestrictionApplicable &&
          cartItems[0]?.childAgeFrom ? (
          <Modal.TextContent>
            Gydytojas konsultuoja tik vaikus nuo {cartItems[0]?.childAgeFrom}{' '}
            metų.
          </Modal.TextContent>
        ) : (
          <Modal.TextContent>
            Gydytojas konsultuoja tik vaikus iki 18 metų.
          </Modal.TextContent>
        )}
        <Modal.Actions>
          <Button
            size="large"
            variant="secondary"
            onClick={() => {
              setShowChildAgeRestrictionModal(false);
            }}
          >
            Gerai
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};

export default BasketPage;
